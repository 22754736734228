import { HiOutlineDocument } from '@react-icons/all-files/hi/HiOutlineDocument';
import { defineArrayMember, defineField, defineType } from '@sanity/types';

import { Document, Image } from '../_types';
import { ksuidIdField, validateSnakeCase } from '../_utils';
import { BlockContentType } from '../block/_types';
import { InlineContentWrapperType } from '../block/InlineContentWrapper';
import {
	arrayOfTypesBoolean,
	ExpressionBooleanType,
} from '../expressions/_types';

export enum AvailabilityType {
	ActiveMember = 'active_member',
	User = 'user',
}

export enum ProgramActivityStateType {
	Live = 'live',
	Archived = 'archived',
}

export type ProgramActivityType = Document & {
	_type: 'ProgramActivity';
	id: string;
	title: string;
	netCoins?: number;
	description: string;
	state: ProgramActivityStateType;
	cardImage: Image;
	condition: ExpressionBooleanType[];
	metadata?: ActivityMetadataType;
	stagingContent: StagingContentType;
	availability: AvailabilityType;
	learning?: LearningType;
	variants?: VariantType[];
	defaultVariant: DefaultVariantType;
};

export type ActivityMetadataType = {
	key: string;
	value: string;
}[];

type StagingContentType = {
	choices?: ChoiceType[];
	headerImage?: Image;
	content: BlockContentType;
};

type BaseCompletableType = {
	durationMins: number;
	flow: { __ref: string }; // __ref prevents automatic dereferencing
	successContent?: {
		title: InlineContentWrapperType;
		content?: InlineContentWrapperType;
		label?: InlineContentWrapperType;
		earnCoinLabel?: InlineContentWrapperType;
		ctaText?: string;
	};
};
type LearningType = BaseCompletableType;
type BaseVariantType = BaseCompletableType & {
	id: string;
};
type VariantType = BaseVariantType & {
	condition: ExpressionBooleanType[];
};
type DefaultVariantType = BaseVariantType;

type ChoiceType = {
	key: string;
	title: string;
	options: OptionType[];
};

type OptionType = {
	key: string;
	name: string;
};

const conditionField = defineField({
	name: 'condition',
	type: 'array',
	of: arrayOfTypesBoolean,
	validation: (r) => r.required().min(1),
	description:
		'Determines whether the user is eligible to receive the activity. If referring to the activity within the condition (e.g. for single-completion activities), publish initially with the "always disabled" condition to avoid self-referencing unpublished documents. All conditions must be met for the activity to be available.',
});

const flowField = defineField({
	name: 'flow',
	type: 'reference',
	validation: (r) => r.required(),
	to: [{ type: 'Flow' }],
});

const durationMinsField = defineField({
	name: 'durationMins',
	type: 'number',
	validation: (r) => r.required().integer().min(1),
	description: 'Duration (not used yet)',
});

const successField = defineField({
	name: 'successContent',
	type: 'object',
	description: 'Content to show when the user completes the activity/learning',
	fields: [
		{
			name: 'title',
			type: 'InlineContentWrapper',
			validation: (r) =>
				r.custom((c?: InlineContentWrapperType) => {
					if (!c) return 'Title is required';
					if (c.content.length !== 1) return 'Title must be a single block';
					return true;
				}),
		},
		{
			name: 'content',
			type: 'InlineContentWrapper',
		},
		{
			name: 'label',
			type: 'InlineContentWrapper',
			validation: (r) =>
				r.custom((c?: InlineContentWrapperType) => {
					if (c && c.content.length !== 1) {
						return 'Label must be a single block';
					}
					return true;
				}),
		},
		{
			name: 'earnCoinLabel',
			type: 'InlineContentWrapper',
			description:
				'Label to show when a user earns a coin after completing an activity',
			validation: (r) =>
				r.custom((c?: InlineContentWrapperType) => {
					if (c && c.content.length !== 1) {
						return 'Label must be a single block';
					}
					return true;
				}),
		},
		{
			name: 'ctaText',
			type: 'string',
		},
	],
});

const SelectionOptionSchema = defineArrayMember({
	type: 'object',
	fields: [
		{
			name: 'key',
			type: 'string',
			validation: (r) => validateSnakeCase(r).required(),
		},
		{
			name: 'name',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
});

const ChoicesSectionSchema = defineArrayMember({
	type: 'object',
	fields: [
		{
			name: 'key',
			type: 'string',
			validation: (r) => validateSnakeCase(r).required(),
		},
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'options',
			type: 'array',
			of: [SelectionOptionSchema],
			validation: (r) =>
				r
					.required()
					.min(1)
					.custom<OptionType[]>((val) => {
						if (val && val.length !== new Set(val.map((e) => e.key)).size) {
							return 'Option keys must be unique';
						}
						return true;
					}),
		},
	],
});

export default defineType({
	name: 'ProgramActivity',
	type: 'document',
	icon: HiOutlineDocument,
	fields: [
		ksuidIdField('prgact', true),
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
			description: 'The title of the activity',
		},
		{
			name: 'netCoins',
			type: 'number',
			description: 'Number of coins earned on completing the activity',
			validation: (r) => r.integer().min(1),
		},
		{
			name: 'description',
			type: 'string',
			description: 'The description of the activity to show on the cards',
		},
		{
			name: 'llm',
			title: 'LLM',
			type: 'object',
			options: { collapsible: true },
			fields: [
				{
					name: 'description',
					type: 'text',
					description: 'The description of the activity to give to the LLM',
					validation: (r) => r.min(10).required(),
				},
				{
					name: 'repeatRecommendation',
					type: 'string',
					description:
						'The repeat recommendation of the activity to give to the LLM',
					validation: (r) => r.required(),
				},
				{
					name: 'repeatRecommendationRationale',
					type: 'text',
					description: 'Explains the benefits of repeating the activity',
				},
				{
					name: 'howItWorks',
					type: 'array',
					description:
						'List of points on how the activity works to give to the LLM',
					of: [{ type: 'string' }],
				},
				{
					name: 'followUpQuestions',
					title: 'Follow-up questions',
					type: 'array',
					description:
						'List of probing questions to encourage reflection after the activity completion',
					of: [{ type: 'string' }],
				},
			],
		},
		{
			name: 'availability',
			type: 'string',
			description: 'Availability of the activity',
			validation: (r) => r.required(),
			initialValue: AvailabilityType.ActiveMember,
			options: {
				list: [
					{ value: AvailabilityType.User, title: 'Any logged-in user' },
					{ value: AvailabilityType.ActiveMember, title: 'Active members' },
				],
			},
		},
		{
			name: 'state',
			type: 'string',
			description: 'State of the activity, we will only use the live version',
			initialValue: ProgramActivityStateType.Live,
			options: {
				list: [
					ProgramActivityStateType.Live,
					ProgramActivityStateType.Archived,
				],
			},
			validation: (r) => r.required(),
		},
		{
			name: 'cardImage',
			description: 'Image to display on the activity card',
			type: 'image',
			validation: (r) => r.required(),
		},
		conditionField,
		{
			name: 'metadata',
			type: 'array',
			description:
				'Generic key/value bucket for metadata - allows filtering. Special rules apply for `masturbatory = true` 👾',
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'key',
							type: 'string',
							validation: (r) => validateSnakeCase(r).required().min(1),
						},
						{
							name: 'value',
							type: 'string',
							validation: (r) => validateSnakeCase(r).required().min(1),
						},
					],
					preview: {
						select: {
							key: 'key',
							value: 'value',
						},
						prepare: ({ key, value }) => ({
							title: `${key} = ${value}`,
						}),
					},
				},
			],
			validation: (r) =>
				r.custom<ActivityMetadataType>((val) => {
					if (val && val.length !== new Set(val.map((e) => e.key)).size) {
						return 'Key must be unique';
					}
					return true;
				}),
		},
		{
			name: 'stagingContent',
			type: 'object',
			description: 'Staging content for the activity',
			fields: [
				{
					name: 'choices',
					type: 'array',
					of: [ChoicesSectionSchema],
					validation: (r) =>
						r.custom<ChoiceType[]>((val) => {
							if (val && val.length !== new Set(val.map((e) => e.key)).size) {
								return 'Choice keys must be unique';
							}
							return true;
						}),
				},
				{
					name: 'headerImage',
					description:
						'Image to display at the top of the exercise for the new staging screens',

					type: 'image',
					validation: (r) => r.required(),
				},
				{
					name: 'content',
					description: 'Main content for the exercise',
					type: 'BlockContent',
					validation: (r) => r.required().min(1),
				},
			],
			validation: (r) => r.required(),
		},
		{
			name: 'learning',
			type: 'object',
			description: 'Theory/learning content',
			fields: [durationMinsField, flowField, successField],
		},
		{
			name: 'variants',
			type: 'array',
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'name',
							type: 'string',
							validation: (r) => r.required(),
						},
						ksuidIdField('prgactvar', true),
						conditionField,
						durationMinsField,
						flowField,
						successField,
					],
					preview: {
						select: {
							title: 'name',
							subtitle: 'id',
						},
					},
				},
			],
			description:
				'Variants (min 0), you can use app context to determine which to show, e.g. depending on what they selected from the choices in the staging content',
		},
		{
			name: 'defaultVariant',
			type: 'object',
			fields: [
				ksuidIdField('prgactvar', true),
				durationMinsField,
				flowField,
				successField,
			],
			description:
				'Default variant, this is the fallback if no condition matches',
			validation: (r) => r.required(),
		},
	],
	validation: (r) =>
		r.custom<Required<ProgramActivityType>>((val) => {
			if (!val) return true;
			const allVariants = [...(val.variants || []), val.defaultVariant];
			const allIds = allVariants.map((v) => v.id);
			if (allIds.length !== new Set(allIds).size) {
				return 'Variant IDs must be unique';
			}
			return true;
		}),
	preview: {
		select: {
			title: 'title',
			subtitle: 'id',
		},
	},
});
