import { defineType } from '@sanity/types';

import { Image } from '../_types';

export type ImageWithAlignmentType = {
	image: Image;
	alignment: 'center';
};

export default defineType({
	name: 'ImageWithAlignment',
	title: 'Image with Alignment',
	type: 'object',
	fields: [
		{
			name: 'image',
			title: 'Image',
			type: 'image',
			validation: (r) => r.required(),
		},
		{
			name: 'alignment',
			title: 'Alignment',
			type: 'string',
			options: {
				list: [{ title: 'Center', value: 'center' }],
				layout: 'radio',
			},
			initialValue: 'center',
			validation: (r) => r.required(),
		},
	],
});
