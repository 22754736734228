import { HiChatAlt } from '@react-icons/all-files/hi/HiChatAlt';
import { defineType } from '@sanity/types';

import { InlineContentWrapperType } from './InlineContentWrapper';

export type AIMessageBlockType = {
	_type: 'AIMessageBlock';
	content: InlineContentWrapperType;
};

export default defineType({
	name: 'AIMessageBlock',
	type: 'object',
	icon: HiChatAlt,
	fields: [
		{
			name: 'content',
			type: 'InlineContentWrapper',
			validation: (r) => r.required(),
		},
	],
	preview: {
		select: {
			messages: 'messages',
		},
		prepare: ({ messages }) => {
			return {
				title: `AI Message with ${messages?.length || 0} messages`,
			};
		},
	},
});
