import { HiOutlineDocumentText } from '@react-icons/all-files/hi/HiOutlineDocumentText';
import { defineType } from '@sanity/types';

import { previewNamedBlockContent } from '../_utils';
import { FlowBasePageType } from './baseTypes';

export type FlowChooseTherapistStepType = FlowBasePageType & {
	_type: 'FlowChooseTherapistStep';
	title: string;
	supportLabel: string;
	nextCtaText: string;
};

export default defineType({
	name: 'FlowChooseTherapistStep',
	type: 'document',
	icon: HiOutlineDocumentText,
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'supportLabel',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
	preview: previewNamedBlockContent('title'),
});
