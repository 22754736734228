import { HiOutlineCalculator } from '@react-icons/all-files/hi/HiOutlineCalculator';
import { defineType } from '@sanity/types';

import { FlowBaseStepType, FlowEfficacyType } from './baseTypes';

export type FlowItoEfficacyMeasurementStepType = FlowBaseStepType & {
	_type: 'FlowItoEfficacyMeasurementStep';
	efficacyType: FlowEfficacyType;
};

export default defineType({
	name: 'FlowItoEfficacyMeasurementStep',
	type: 'document',
	icon: HiOutlineCalculator,
	fields: [
		{
			name: 'efficacyType',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'mode',
					type: 'string',
					validation: (r) => r.required(),
					options: {
						list: [
							{ title: 'Cancelation', value: 'cancelation' },
							{ title: 'Manual', value: 'manual' },
							{ title: 'Onboarding', value: 'onboarding' },
						],
					},
				},
				{
					name: 'onboardingType',
					type: 'string',
					options: {
						list: [
							{ title: 'App', value: 'app' },
							{ title: 'Recapture', value: 'recapture' },
						],
					},
					validation: (r) =>
						r.custom<string>((value, { parent }) => {
							const v =
								parent as FlowItoEfficacyMeasurementStepType['efficacyType'];
							return v.mode === 'onboarding' && !value
								? 'Onboarding type is required'
								: true;
						}),
				},
			],
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
	],
	preview: {
		select: {
			mode: 'efficacyType.mode',
		},
		prepare(selection) {
			const { mode } =
				selection as FlowItoEfficacyMeasurementStepType['efficacyType'];
			return {
				title: `Efficacy Questions - ${mode}`,
			};
		},
	},
});
