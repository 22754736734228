import { defineField, defineType } from '@sanity/types';

import { Document } from '../_types';
import { BlockContentWrapperType } from '../block/BlockContentWrapper';
import { InlineContentType } from '../block/InlineContent';

type ModalFragment = {
	title: string;
	content: BlockContentWrapperType;
	ctaText: string;
	secondaryCtaText?: string;
};

export type FragmentMemoriesType = Document & {
	_type: 'FragmentMemories';
	title: string;
	ctaButtonTitle: string;
	empty: {
		title: InlineContentType;
		subtitle: string;
	};
	deleteConfirmationModal: ModalFragment;
};

const emptyCardFields = [
	defineField({
		name: 'title',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'subTitle',
		type: 'string',
		validation: (r) => r.required(),
	}),
];

const modalContent = [
	defineField({
		name: 'title',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'content',
		type: 'BlockContentWrapper',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'ctaText',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'secondaryCtaText',
		type: 'string',
	}),
];

export default defineType({
	name: 'FragmentMemories',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'ctaButtonTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'emptyCard',
			deprecated: {
				reason: 'Replaced by `empty`',
			},
			type: 'object',
			fields: emptyCardFields,
		},
		{
			name: 'empty',
			type: 'object',
			fields: [
				{
					name: 'title',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'subtitle',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'deleteConfirmationModal',
			type: 'object',
			fields: modalContent,
		},
	],
});
