import { HiOutlineDocumentText } from '@react-icons/all-files/hi/HiOutlineDocumentText';
import { defineType } from '@sanity/types';

import { Image } from '../_types';
import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { FlowBasePageType } from './baseTypes';

export type FlowContentStepType = FlowBasePageType & {
	_type: 'FlowContentStep';
	hideProgressBar?: boolean;
	content: BlockContentType;
	headerImage?: Image;
	headerImageAsBackground?: boolean;
	contentAppend?: BlockContentType;
};

export default defineType({
	name: 'FlowContentStep',
	type: 'document',
	icon: HiOutlineDocumentText,
	fields: [
		{
			name: 'icon',
			type: 'Icon',
		},
		{
			name: 'headerImageAsBackground',
			type: 'boolean',
			description:
				"Note: Don't use these within the onboarding flows. If true, the header image will be used as a background image otherwise as a full width image.",
			initialValue: false,
		},
		{
			name: 'hideProgressBar',
			type: 'boolean',
			description: 'If true, the progress bar will not be shown on the screen.',
			initialValue: false,
		},
		{
			name: 'headerImage',
			type: 'image',
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.length(1),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'contentAppend',
			type: 'BlockContent',
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
	],
	preview: previewNamedBlockContent('title'),
});
