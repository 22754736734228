import { HiOutlineDocumentText } from '@react-icons/all-files/hi/HiOutlineDocumentText';
import { defineType } from '@sanity/types';

import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { FlowBasePageType } from './baseTypes';

export type FlowNameStepType = FlowBasePageType & {
	_type: 'FlowNameStep';
	content: BlockContentType;
	inputFieldLabel: string;
};

export default defineType({
	name: 'FlowNameStep',
	type: 'document',
	icon: HiOutlineDocumentText,
	fields: [
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.length(1),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'inputFieldLabel',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
	],
	preview: previewNamedBlockContent('title'),
});
