import { Document } from '../_types';
import { ExpressionBooleanType } from '../expressions/_types';
import { FlowAudioPlayerStepType } from './FlowAudioPlayerStep';
import { FlowCalendlyBookingStepType } from './FlowCalendlyBookingStep';
import { FlowChooseTherapistStepType } from './FlowChooseTherapistStep';
import { FlowCommunityTopicStepType } from './FlowCommunityTopicStep';
import { FlowContentStepType } from './FlowContentStep';
import { FlowEfficacyMeasurementStepType } from './FlowEfficacyMeasurementStep';
import { FlowExerciseRESupportStepType } from './FlowExerciseRESupportStep';
import { FlowExerciseTimedBoxBreathAnimationStepType } from './FlowExerciseTimedBoxBreathAnimationStep';
import { FlowExerciseTimedContentStepType } from './FlowExerciseTimedContentStep';
import { FlowExerciseTimedImageStepType } from './FlowExerciseTimedImageStep';
import { FlowFullScreenVideoStepType } from './FlowFullScreenVideoStep';
import { FlowItoEfficacyMeasurementStepType } from './FlowItoEfficacyMeasurementStep';
import { FlowItoInnerCriticVisualizeStepType } from './FlowItoInnerCriticVisualizeStep';
import { FlowItoLoadingAnimationStepType } from './FlowItoLoadingAnimationStep';
import { FlowItoTextStepType } from './FlowItoTextStep';
import { FlowNameStepType } from './FlowNameStep';
import { FlowNotificationsPreferenceStepType } from './FlowNotificationsPreferenceStep';
import { FlowOnboardingActivityStepType } from './FlowOnboardingActivityStep';
import { FlowOnboardingGoalsStepType } from './FlowOnboardingGoalsStep';
import { FlowPelvicExerciseStepType } from './FlowPelvicExerciseStep';
import { FlowPelvicFloorExerciseCompleteStepType } from './FlowPelvicFloorExerciseCompleteStep';
import { FlowPollQuestionAnswerStepType } from './FlowPollQuestionAnswerStep';
import { FlowPollStepType } from './FlowPollStep';
import { FlowReverseKegelExerciseStepType } from './FlowReverseKegelExerciseStep';
import { FlowSWScoreOnboardingResultStatStepType } from './FlowSWScoreOnboardingResultStatStep';
import { FlowSWScoreOnboardingResultStepType } from './FlowSWScoreOnboardingResultStep';
import { FlowSWScoreOnboardingStepType } from './FlowSWScoreOnboardingStep';
import { FlowWrapUpStepType } from './FlowWrapUpStep';

export const flowStepTypes = [
	'FlowAudioPlayerStep',
	'FlowCalendlyBookingStep',
	'FlowCommunityTopicStep',
	'FlowContentStep',
	'FlowChooseTherapistStep',
	'FlowEfficacyMeasurementStep',
	'FlowExerciseRESupportStep',
	'FlowExerciseTimedBoxBreathAnimationStep',
	'FlowExerciseTimedContentStep',
	'FlowExerciseTimedImageStep',
	'FlowFullScreenVideoStep',
	'FlowItoEfficacyMeasurementStep',
	'FlowItoInnerCriticVisualizeStep',
	'FlowNameStep',
	'FlowNotificationsPreferenceStep',
	'FlowOnboardingActivityStep',
	'FlowOnboardingGoalsStep',
	'FlowPelvicExerciseStep',
	'FlowPelvicFloorExerciseCompleteStep',
	'FlowPollQuestionAnswerStep',
	'FlowPollStep',
	'FlowReverseKegelExerciseStep',
	'FlowSWScoreOnboardingResultStatStep',
	'FlowSWScoreOnboardingResultStep',
	'FlowSWScoreOnboardingStep',
	'FlowWrapUpStep',
	'FlowItoTextStep',
	'FlowItoLoadingAnimationStep',
];

export type FlowStepType =
	| FlowAudioPlayerStepType
	| FlowCalendlyBookingStepType
	| FlowCommunityTopicStepType
	| FlowContentStepType
	| FlowChooseTherapistStepType
	| FlowEfficacyMeasurementStepType
	| FlowExerciseRESupportStepType
	| FlowExerciseTimedBoxBreathAnimationStepType
	| FlowExerciseTimedContentStepType
	| FlowExerciseTimedImageStepType
	| FlowFullScreenVideoStepType
	| FlowItoEfficacyMeasurementStepType
	| FlowItoInnerCriticVisualizeStepType
	| FlowNameStepType
	| FlowNotificationsPreferenceStepType
	| FlowPelvicExerciseStepType
	| FlowReverseKegelExerciseStepType
	| FlowPelvicFloorExerciseCompleteStepType
	| FlowOnboardingActivityStepType
	| FlowOnboardingGoalsStepType
	| FlowPollQuestionAnswerStepType
	| FlowPollStepType
	| FlowSWScoreOnboardingResultStatStepType
	| FlowSWScoreOnboardingResultStepType
	| FlowSWScoreOnboardingStepType
	| FlowWrapUpStepType
	| FlowItoTextStepType
	| FlowItoLoadingAnimationStepType;

export const flowItemTypes = [...flowStepTypes, 'FlowGroup'];
export type FlowItemType = FlowStepType | FlowGroupType;

export type FlowGroupType = Document & {
	_type: 'FlowGroup';
	cases?: FlowGroupCaseType[];
	defaultItems?: FlowItemType[];
};

type FlowGroupCaseType = {
	condition: ExpressionBooleanType[];
	items?: FlowItemType[];
};
