import { HiOutlineDocumentText } from '@react-icons/all-files/hi/HiOutlineDocumentText';
import { defineField, defineType } from '@sanity/types';

import { FlowBaseStepType } from './baseTypes';

export type FlowItoLoadingAnimationStepType = FlowBaseStepType & {
	_type: 'FlowItoLoadingAnimationStep';
	hideProgressBar?: boolean;
	items: LoadingItem[];
	nextCtaText: string;
};

type LoadingItem = {
	text: string;
};

const loadingItemFields = [
	defineField({
		name: 'text',
		type: 'string',
		validation: (r) => r.required(),
	}),
];

export default defineType({
	name: 'FlowItoLoadingAnimationStep',
	type: 'document',
	icon: HiOutlineDocumentText,
	fields: [
		{
			name: 'hideProgressBar',
			type: 'boolean',
			description: 'If true, the progress bar will not be shown on the screen.',
			initialValue: false,
		},
		{
			name: 'items',
			type: 'array',
			validation: (r) => r.required().length(4),
			of: [
				{
					name: 'loadingItem',
					type: 'object',
					fields: loadingItemFields,
					preview: {
						select: {
							title: 'text',
						},
					},
				},
			],
		},
		{
			name: 'nextCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
});
